import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useDispatch } from 'react-redux';
import { BrowserMultiFormatReader } from '@zxing/library';

import { FooterNavigation } from '../../components/footer-navigation.component';
import { Button } from '../../components/button.component';
import { FormControl } from '../../components/form-control.component';
import { Input } from '../../components/input.component';
import { StepsCompleted, useRegistration } from '../../hooks/use-registration.hook';
import { usePrefillcode } from '../../hooks/use-prefillcode.hook';
import { saveBarcode } from '../../services/registrations.service';
import { setRegistration } from '../../states/registrations.slice';
import { RegisterLayout } from '../../components/register-layout.component';

const numberGroups = 3;
const numbersPerGroup = 4
const numberOfDigits = numberGroups * numbersPerGroup;

export default function Index() {
    const { navigate, t } = useI18next();
    const dispatch = useDispatch();
    const videoElement = useRef();
    const codeReader = useRef();
    const stream = useRef();
    const [showToolTip, setShowToolTip] = useState(false);
    const [showScanResult, setShowScanResult] = useState(false);
    const [currentCamera, setCurrentCamera] = useState(1);
    const [isCameraOn, setIsCameraOn] = useState(false);
    
    const registration = useRegistration({ currentStep: StepsCompleted.Pcr.ScanBarcode });

    const [error, setError] = useState('');
    const [isError, setIsError] = useState(false);
    
    //barcode input as string
    const initBarcodeString = usePrefillcode(registration);
    const [result, setResult] = useState([]);

    const [barcodeNums, setBarcodeNums] = useState([]);

    //barcode input as array of characters
    useEffect(() => {
        const initBarcodeArray = initBarcodeString.split('');
        for (let i=0; i < numberOfDigits; i++) {
            if (typeof initBarcodeArray[i] === 'undefined') {
                initBarcodeArray[i] = '';
            }
        }
        setBarcodeNums(initBarcodeArray);
        setResult(initBarcodeString);
    }, [initBarcodeString])

    const setBarcode = useCallback((index, value, noFocusChange) => {
        const numbers = [...barcodeNums];

        let newFocusIndex = index + 1;
        let onLastElem = false;

        if (value === "") {
            numbers[index] = "";
            newFocusIndex = index-1;
        } else {
            const input = value.split("");

            //if multiple numbers are being fed on the input, spread them over to the other fields
            for (let i = index; i < numberOfDigits; i++) {
                if (typeof input[i - index] !== 'undefined') {
                    let num = parseInt(input[i - index]);
                    //sanitize input
                    if (isNaN(num)) {
                        num = "";
                        newFocusIndex = index;
                    }
                    numbers[i] = num;
                    if (i === numberOfDigits - 1) {
                        //to which element we should focus, e.g. if we input 6 numbers we should go to input 7
                        onLastElem = true;
                    }
                }
            }
        }

        //make sure it is at least 0 and max 8
        newFocusIndex = Math.min(numberOfDigits - 1, Math.max(0, newFocusIndex));
        if (!noFocusChange && onLastElem) {
            document.activeElement.blur();
        } else if (index !== newFocusIndex && !noFocusChange) {
            document.getElementById("barcode-" + newFocusIndex).focus()
            numbers[newFocusIndex] = "";
        }
        //set barcode array
        setBarcodeNums(numbers);
        //set barcode string
        setResult(numbers.join(''));
    }, [barcodeNums, setBarcodeNums, setResult, numberOfDigits]);

    const onKeyDown = useCallback((index, keyCode) => {
        if (keyCode === 8) {//backspace
            //focus previous element
            const newFocusIndex = Math.min(numberOfDigits - 1, Math.max(0, index-1));
            if (index !== newFocusIndex) {
                document.getElementById("barcode-" + newFocusIndex).focus()
            }
        }
    }, [numberOfDigits]);

    const [accessGiven, setAccessGiven] = useState(false);
    const [sources, setSources] = useState([]);
    const [selectedSource, setSelectedSource] = useState('');

    const onStartScan = useCallback(() => {
        setIsError(false);
        setShowScanResult(false);
        setIsCameraOn(true);
        navigator.mediaDevices.getUserMedia({ video: true }).then(st => {
            stream.current = st;
            setAccessGiven(true);
        });
    }, [setAccessGiven]);

    const handleTurnOffCamera = () => {
        setSources([]);
        setIsCameraOn(false);
        setAccessGiven(false);
        setSelectedSource('');
        codeReader.current.reset();
        if(stream.current) {
                stream.current.getTracks().forEach(track => {
                track.stop();
            });
        }
    };

    const getFixedBarCode = (resultText) => resultText.substr(-12);

    useEffect(() => {
        if (selectedSource) {
            codeReader.current.decodeFromVideoDevice(selectedSource, videoElement.current, (result, error) => {
                if (result) {
                    const codeFromCamera = getFixedBarCode(result.text);
                    setShowScanResult(true);
                    setResult(codeFromCamera);
                    setBarcodeNums(codeFromCamera);
                    // close camera when finished scan
                    codeReader.current.reset();
                    setAccessGiven(false);
                    setSelectedSource('');
                    setIsCameraOn(false);
                    if(stream.current) {
                        stream.current.getTracks().forEach(track => {
                          track.stop();
                        });
                      }
                }
                if (error) {
                    setError(JSON.stringify(error));
                }
            });
        }
    }, [selectedSource]);

    useEffect(() => {
        if (accessGiven) {
            codeReader.current = new BrowserMultiFormatReader();
            codeReader.current
                .listVideoInputDevices()
                .then(videoInputDevices => {
                    const cameraSources = [];
                    videoInputDevices.forEach(device => cameraSources.push({ value: device.deviceId, label: device.label }));
                    setSources(cameraSources);

                    if (cameraSources.length === 1) {
                        setSelectedSource(cameraSources[0].value);
                    }

                    // set back camera as default device
                    //@Todo: Base the default device on label "back";
                    if (cameraSources.length === 2) {
                        const sources = cameraSources.filter(source => source.label.toLowerCase().includes('back'));
                        setSelectedSource(sources[0].value);
                    }
                })
                .catch(err => console.error(err));
        }
    }, [accessGiven]);


    const onSubmitBarcode = useCallback(() => {
        saveBarcode(result, registration.id).then(response => {
            dispatch(setRegistration(response.data.registration));
            // if(stream.current) {
            //   stream.current.getTracks().forEach(track => {
            //     track.stop();
            //   });
            // }
            setSources([]);

            if (codeReader.current) {
                try {
                    codeReader.current.reset().then(() => {
                        navigate('/register/consent');
                    });
                }
                catch (e) {
                    navigate('/register/consent');
                }
            } else {
                navigate('/register/consent');
            }
        })
        .catch(error => {
            setIsError(true);
            console.error('barcode error', error);
        });
    }, [result, registration, dispatch, navigate]);

    const handleManualInput = () => {
        setShowToolTip(true);
        setTimeout(() => {
            setShowToolTip(false);
        }, 5000);
    }
    
    const handleSwitchCamera = () => {
        if (sources.indexOf(selectedSource) === 1) {
            setCurrentCamera(0);
        } else {
            setCurrentCamera(1);
        }
        setSelectedSource(sources[currentCamera].value);
    }

    return <RegisterLayout className="create-account narrow-nav" backLocation="/register/info">
        <div className="section section--scanning-code animateIn">
            <h1 className="t-h2 t-gray9">{t('Test number register')}</h1>
            <p className="t-sans t-gray9 t-p1">{t('You will need the QR code or sample number printed on the collection tube label in the test kit')}</p>
            <div className="scan-options">
                <Button className="scan-option t-sans" onClick={onStartScan}>{t('Scan QR Code')}</Button>
                <Button className="scan-option type-default t-sans" onClick={handleManualInput}>{t('Manual Input')}</Button>
            </div>
            <FormControl label={t('Test kit number')}>
                <div className={`input-barcode-numbers${showToolTip ? ' highlight' : ''}${isError ? ' barcode-error' : ''}`}>
                {isError && <p className="t-sans t-bold t-orange error-msg">{t('Code is not valid')}</p>}
                    {Array.apply(null, { length: numberGroups }).map((e, i) => (
                        <React.Fragment key={i}>
                            <div className="group">
                                {Array.apply(null, { length: numbersPerGroup }).map((e, i2) => {
                                    const index = (numbersPerGroup * i) + i2;
                                    return (
                                        <Input
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            type="text"
                                            placeholder="0"
                                            key={index}
                                            id={`barcode-${index}`}
                                            value={barcodeNums[index]}
                                            onChange={e => setBarcode(index, e.target.value)}
                                            onFocus={e => {setBarcode(index, "", true); setIsError(false);}}
                                            onKeyDown={e => onKeyDown(index, e.keyCode)}
                                        />
                                    );
                                })}
                            </div>
                            {i !== (numberGroups - 1) && (
                                //note, special dash character "ー"
                                <div className="group-spacer">ー</div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </FormControl>
        </div>
        <div className="section section--scan-area">
            {showScanResult && (
                <div className="scan-notification">
                    <p className="t-sans t-subheading3">{t('Barcode has been scanned')}</p>
                </div>
                )}

            <video ref={videoElement} id="video" width="100%" controls={false}></video>
            {/* {sources.length > 1 && (
                <select onChange={e => setSelectedSource(e.target.value)} value={selectedSource}>
                    <option value="">Select an input device</option>
                    { sources.map(source => <option key={source.value} value={source.value}>{source.label}</option>)}
                </select>
            )} */}
            {isCameraOn && <Button className="scan-option type-default t-sans" onClick={handleTurnOffCamera}>{t('Turn Off Camera')}</Button>}
            {sources.length > 1 && (
                <button className="hap-button scan-option type-default t-sans" type="button" onClick={handleSwitchCamera}>{t('Switch Camera')}</button>
            )}
        </div>
        <FooterNavigation
            steps={5}
            activeStep={2}
            label={t('Next')}
            disabled={result.length < numberOfDigits}
            onClick={onSubmitBarcode}
        />
    </RegisterLayout>;
}