import { useEffect, useState } from "react";

export const usePrefillcode = (registration) => {
    const [prefilledCode, setPrefilledCode] = useState('');
    useEffect(() => {
        const code = window.localStorage.getItem('prefill');
        if (code) {
            setPrefilledCode(code);
        }
    }, [])
    return (prefilledCode || registration.barcode || '').substr(0, 12);
}